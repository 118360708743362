import React from 'react';

function DiscordBots() {
  return (
    <div className="App-content">
      <h1>Discord Bots</h1>
      <p>Placeholder for Discord Bots content.</p>
    </div>
  );
}

export default DiscordBots;

import React from 'react';

function Contact() {
  return (
    <div className="App-content">
      <h1>Contact</h1>
      <p>Placeholder for Contact content.</p>
    </div>
  );
}

export default Contact;

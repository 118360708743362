import React from 'react';
import './App.css';
import logo from './dasker_logo.png';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import DiscordBots from './DiscordBots';
import TwitchBots from './TwitchBots';
import Contact from './Contact';

function App() {
  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <div className="logo-container">
            <img src={logo} className="App-logo" alt="logo" />
          </div>
          <div className="nav-container">
            <nav>
              <ul>
                <li><Link to="/discord-bots">Discord Bots</Link></li>
                <li><Link to="/twitch-bots">Twitch Bots</Link></li>
                <li><Link to="/contact">Contact</Link></li>
              </ul>
            </nav>
          </div>
        </header>
        <Routes>
          <Route path="/" element={
            <div className="App-content">
              <h1>Welcome to Dasker</h1>
              <p>Your one-stop solution for Discord and Twitch bots.</p>
            </div>
          } />
          <Route path="/discord-bots" element={<DiscordBots />} />
          <Route path="/twitch-bots" element={<TwitchBots />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
        <footer className="App-footer">
          © 2024 Dasker.co.uk
        </footer>
      </div>
    </Router>
  );
}

export default App;
